<template>
  <div class="mcr-gallery">
    <h3>{{ title }}</h3>
    <preview-images-gallery
      :images-src="imagesSrc"
      :lazy-load="true"
      :setup-gallery-data="setupGalleryData"
    ></preview-images-gallery>
  </div>
</template>

<script>
import PreviewImagesGallery from '@common/elements/gallery/PreviewImagesGallery';
import {getGalleryImageData} from '@common/utils/utils';

export default {
  props: {
    title: String,
    images: String,
    tags: String,
  },
  data() {
    return {
      splitTag: '【mcr】',
    };
  },
  computed: {
    imagesSrc() {
      return this.images.split(this.splitTag);
    },
    tagsData() {
      return this.tags.split(this.splitTag);
    },
  },
  methods: {
    setupGalleryData(imagesData) {
      return imagesData.map(item => {
        return getGalleryImageData(item.id, item.src, this.tagsData[item.id], {title: this.title});
      });
    },
  },
  components: {PreviewImagesGallery},
  name: 'McrGallery',
};
</script>

<style lang="scss" scoped>
.mcr-gallery {
  background-color: var(--report-gallery-background);
  padding: 40px $desktop-content-padding;
  clear: both;
  margin: 40px auto;
  border-radius: 4px;

  @media only screen and (max-width: $breakpoint-phablet) {
    padding: $mobile-se-content-padding;
  }
}
</style>
